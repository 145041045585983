@font-face {
  font-family: "Noto Sans KR";
  src: url("https://fonts.googleapis.com/css2?family=Noto+Sans+KR:wght@300;400;500;700&display=swap");
  unicode-range: U+AC00-D7A3;
}
@font-face {
  font-family: "Roboto";
  src: url("https://fonts.googleapis.com/css2?family=Roboto:wght@100;300;400;500;700&display=swap");
  unicode-range: U+0041-005A, U+0061-007A;
}

@font-face {
  font-family: "Pretendard-Regular";
  src: url("https://cdn.jsdelivr.net/gh/Project-Noonnu/noonfonts_2107@1.1/Pretendard-Regular.woff")
    format("woff");
  font-weight: 400;
  font-style: normal;
}
.backwhite {
  background-color: white !important;
}
body {
  background-color: #ffffff !important;
  // font-family: 'Noto Sans KR', 'Roboto' !important;
  font-family: "Pretendard-Regular" !important;
}
* {
  .material-icons-round {
    font-family: "Material Icons Round" !important;
  }
  font-family: inherit !important;
}
::-webkit-scrollbar {
  width: 12px;
  height: 12px;
}
::-webkit-scrollbar-thumb {
  background-color: #ced4d7;
  border-radius: 10px;
  background-clip: padding-box;
  border: 2px solid transparent;
}
::-webkit-scrollbar-track {
  background-color: transparent;
  border-radius: 10px;
  box-shadow: inset 0px 0px 5px white;
}
.main-layout {
  min-width: 1320px;
  // height: 100vh;
}
.MuiInputLabel-root {
  font-size: 1rem !important;
}
.smallLabel {
  font-size: 14px !important;
}
.profile-select {
  .MuiOutlinedInput-root {
    height: 60px !important;
    border-radius: 0px 8px 8px 0px !important;
    .MuiSelect-select {
      font-size: 18px;
      font-weight: 700;
      text-align: center;
    }
    fieldset {
      border: none !important;
    }
  }
}
.setting-selectbox {
  .Mui-disabled {
    background-color: #f7f9fb !important;
  }
  .MuiFormLabel-filled {
    transform: translate(14px, -9px) scale(0.75) !important;
  }
  label {
    transform: translate(14px, 10px) scale(1) !important;
  }
  &.select-none-active {
    .MuiOutlinedInput-root fieldset legend {
      font-size: 0px;
      width: 0px;
    }
  }
  &.backCout {
    .MuiOutlinedInput-root fieldset legend {
      width: 50px;
    }
  }
  .MuiOutlinedInput-root {
    text-align: left !important;
    border: none !important;
  }
  .MuiInputLabel-root {
    left: -3px !important;
  }
}
.setting-selectbox-manger {
  .MuiFormLabel-filled {
    transform: translate(14px, -9px) scale(0.75) !important;
  }
  label {
    transform: translate(14px, 10px) scale(1) !important;
  }
  &.select-none-active {
    .MuiOutlinedInput-root fieldset legend {
      font-size: 0px;
      width: 0px;
    }
  }
  &.backCout {
    .MuiOutlinedInput-root fieldset legend {
      width: 50px;
    }
  }
  .MuiOutlinedInput-root {
    text-align: center !important;
    border: none !important;
  }
  .MuiInputLabel-root {
    left: -3px !important;
  }
}
.setting-head-selectbox {
  .MuiOutlinedInput-root {
    padding: 0px !important;
    border: none !important;
    min-height: 0px !important;
    .MuiOutlinedInput-input {
      text-align: center !important;
      border: none !important;
      font-weight: bold !important;
      font-size: 16px !important;
      color: #535353 !important;
    }
    .MuiOutlinedInput-notchedOutline {
      border: none !important;
    }
    .MuiSvgIcon-fontSizeMedium {
      margin-right: -20px;
      color: #535353 !important;
    }
  }
}

.data-picker-textfiled-birthday {
  .Mui-focused:first-child {
    transform: translate(14px, -9px) scale(0.75) !important;
  }
  .MuiFormLabel-filled {
    transform: translate(14px, -9px) scale(0.75) !important;
  }
  label {
    transform: translate(14px, 10px) scale(0.9) !important;
  }
  .MuiOutlinedInput-root {
    border: none !important;
    .MuiOutlinedInput-input {
      color: #000000 !important;
    }
  }
  .MuiInputLabel-root {
    left: -3px !important;
  }
  .MuiInputBase-root {
    border-radius: 10px !important;
    .MuiInputAdornment-root {
      margin-left: 134px;
      margin-top: -20px;
    }
  }
}
.data-picker-textfiled {
  .Mui-focused:first-child {
    transform: translate(14px, -9px) scale(0.75) !important;
  }
  .MuiFormLabel-filled {
    transform: translate(14px, -9px) scale(0.75) !important;
  }
  label {
    transform: translate(14px, 10px) scale(0.9) !important;
  }
  .MuiOutlinedInput-root {
    border: none !important;
    .MuiOutlinedInput-input {
      color: #000000 !important;
    }
  }
  .MuiInputLabel-root {
    left: -3px !important;
  }
  .MuiInputBase-root {
    border-radius: 10px !important;
    .MuiInputAdornment-root {
      margin-left: 74px;
      margin-top: -20px;
    }
  }
}
.MuiOutlinedInput-root {
  min-height: 40px !important;
}
.login-bg {
  background: url("./images/login-bg.png") no-repeat !important;
  background-size: cover !important;
}
.footer-copy {
  position: absolute;
  width: 100%;
  bottom: 40px;
}
.letter-0-8px {
  letter-spacing: -0.8px !important;
}
.component-error-text {
  margin-top: 6px !important;
  color: red !important;
}
.component-success-text {
  margin-top: 6px !important;
  color: #00c160 !important;
}
.reset-password-text {
  cursor: pointer;
  text-decoration: underline !important;
  text-underline-position: under !important;
}
.back-btn {
  min-width: 40px !important;
  padding: 0 !important;
  line-height: initial !important;
}
.back-btn-layout {
  position: absolute;
  bottom: 32px;
}
.dialog-menu-tabs .MuiTabs-root .MuiTabs-scroller .MuiTabs-flexContainer .Mui-selected {
  color: black !important;
}
.outlined-bg-white {
  background-color: white !important;
}
/* leftNav style */
.leftNav-bt {
  margin-right: 0 !important;
  border-top-left-radius: 0 !important;
  border-bottom-left-radius: 0 !important;
}
.leftNav-bt-mini {
  margin-right: 0 !important;
  border-top-left-radius: 0 !important;
  border-bottom-left-radius: 0 !important;
  padding-right: 0 !important;
}
.leftNav-bg .MuiPaper-root {
  width: 300px !important;
  background: url("./images/leftNav_bg2x.png") !important;
  margin: -12px 0px 0px 0px !important;
  height: 101.3vh !important;
  border: none !important;
  border-radius: none !important;
  padding: 0px !important;
  margin-left: -16px !important;
  overflow: hidden;
}
.leftNav-bg-mini .MuiPaper-root {
  // width: 5rem !important;
  // background: url('./images/leftNav_bg2x.png') !important;
  margin: 0px !important;
  height: 100vh !important;
  border-right: 2px solid #f1f1f5 !important;
  border-radius: 0px !important;
  padding: 0px !important;
  margin-left: -16px !important;
}
.leftNav-arrow {
  text-align: end !important;
}
.leftNav-decobox {
  margin-left: -20px !important;
  margin-right: 15px;
  box-shadow: none !important;
}
.changeGradeCount-style-zero {
  color: #0c65ff;
  font-weight: 700;
}
.changeGradeCount-style {
  color: #0c65ff;
  font-weight: 700;
  text-decoration: underline;
  text-underline-position: unset;
}
.setting-border-radius-right {
  border: 1px !important;
  border-radius: 10px 0 0 10px !important;
}
.setting-border-radius {
  border: 1px !important;
  border-radius: 0.5rem !important;
  .MuiOutlinedInput-input {
    color: #000000;
    font-size: 16px !important;
  }
}
.setting-border-radius-small {
  border: 1px !important;
  border-radius: 0.5rem !important;
  .MuiOutlinedInput-input {
    color: #000000;
    font-size: 14px !important;
  }
}
.search-border-radius-small {
  border: 1px !important;
  border-radius: 0.5rem 0 0 0.5rem !important;
  .MuiOutlinedInput-input {
    color: #000000;
    font-size: 14px !important;
  }
}
.filter-Open-border-radius {
  border: 1px !important;
  border-radius: 0.5rem 0 0 0 !important;
  .MuiOutlinedInput-input {
    color: #000000;
    font-size: 14px !important;
  }
}

.reload-btn {
  margin-left: 10px !important;
  border-radius: 4px !important;
  border: 1px solid grey !important;
  width: 14px !important;
  height: 14px !important;
  padding: 1px !important;
}
.event-text {
  cursor: pointer;
  color: #0c65ff;
  text-decoration: underline;
}
.ml-10 {
  margin-left: 10px !important;
}
.ml-25 {
  margin-left: 25px !important;
}
/* Header  */
.header-badge .MuiBadge-badge {
  background-color: #f55cc7 !important;
  min-width: 12px !important;
  height: 12px !important;
}
.header-menu .MuiPaper-root {
  margin-top: 40px;
  margin-left: 13px !important;
  min-width: 480px !important;
  min-height: 400px !important;
}
.profile-defalut-icon {
  background-color: #f7f9fb !important;
  color: black !important;
  cursor: default !important;
}
.SuiInput-right-icon .MuiFormControl-root div {
  border-radius: 0.5rem !important;
  color: #000000 !important;
}
.alert-dialog-layout-levelup .MuiDialog-container {
  min-height: 256px !important;
}
.alert-dialog-layout-levelup .MuiDialog-container div {
  min-width: 336px !important;
  border-radius: 10px !important;
}
.alert-dialog-layout .MuiDialog-container {
  min-height: 256px !important;
}
.alert-dialog-layout .MuiDialog-container div {
  min-width: 480px !important;
  border-radius: 10px !important;
}
.backCount-left-input {
  border-left-width: 0px !important;
  border-radius: 0 10px 10px 0 !important;
}
.filter-right-icon .MuiFormControl-root div {
  border-radius: 10px !important;
}
.left_no_radius .MuiFormControl-root div {
  border-radius: 0px 10px 10px 0px !important;
}
.filter-input .MuiFormControl-root div {
  border-radius: 0 10px 10px 0 !important;
}
.filter-detail-open .MuiFormControl-root div {
  border-radius: 10px 10px 0 0 !important;
  border-bottom: none !important;
}
.filter-right-icon-filter-open .MuiFormControl-root div {
  border-radius: 10px 10px 0 0 !important;
  border-bottom: none !important;
}
.filter-overlay-radius {
  background-color: #ffffff !important;
  border-radius: 10px 10px 0 0 !important;
}
.filter-border-radius {
  border: 1px !important;
  border-radius: 10px 0 0 10px !important;
}
.filter-border-radius-filter-open {
  fieldset {
    border-bottom: none !important;
  }
  border-radius: 10px 0 0 0 !important;
}
.Table-Select-Box {
  min-height: 36px !important;
}
.cursor {
  cursor: pointer;
}
th {
  letter-spacing: -1px !important;
  font-weight: 700 !important;
}
td {
  letter-spacing: -1px !important;
}

.width80 {
  width: 80px !important;
}
.filter-checkbox .MuiFormControlLabel-label {
  font-weight: 500 !important;
  color: #707070 !important;
  font-size: 14px !important;
}
.filter-chips {
  border-radius: 10px !important;
}
.oneSelect-filter-chips {
  font-size: 0.72rem !important;
  span {
    padding: 0 !important;
  }
  border-radius: 10px !important;
}
.select-icon {
  position: absolute;
  right: 10px;
}
.point-text {
  color: #5c38ff;
}
.Mui-disabled {
  color: #ffffff !important;
  box-shadow: none !important;
  background-color: #ced4d7 !important;
}
.Fileupload-textArea div {
  border-radius: 0.5rem !important;
  border: none !important;
  box-shadow: none !important;
}
.Fileupload-textArea {
  .Mui-disabled {
    -webkit-text-fill-color: #535353 !important;
    color: #535353 !important;
    background-color: #f7f9fb !important;
    border: none !important;
  }
}
.width100 {
  width: 100% !important;
}
.cursorDefalut {
  cursor: default !important;
}
.icon-primary {
  svg {
    polyline {
      stroke: #5c38ff;
    }
  }
}
.TreeView-style {
  background-color: #f7f9fb;
  padding: 20px !important;
  border-radius: 10px !important;
}
.TreeItem-style {
  margin-bottom: 4px !important;
  .Mui-selected {
    background-color: transparent !important;
  }
  div {
    .MuiTreeItem-label {
      font-weight: 700;
    }
    .MuiTreeItem-group {
      .MuiTypography-root {
        padding-left: 10px;
      }
    }
  }
  div {
    .MuiTreeItem-label {
      font-size: 14px !important;
    }
  }
}
.blackColor {
  color: black !important;
}
.pdfColor {
  path {
    stroke: #b3404a;
  }
}
.profile-top {
  height: 84px;
  background: url("./images/header_bg.png") no-repeat !important;
}
.showData-selectbox {
  .MuiOutlinedInput-root {
    height: 57px !important;
    background-color: #f7f9fb !important;
    .Mui-disabled {
      -webkit-text-fill-color: rgba(0, 0, 0, 1);
      color: black !important;
      background-color: #f7f9fb !important;
    }
    fieldset {
      border: none !important;
    }
  }
}
.svg-disabled {
  .MuiSvgIcon-root {
    path {
      opacity: 0.3 !important;
    }
  }
}

.Quill-layout {
  width: 100%;
  height: 340px;
  display: flex;
  flex-direction: column-reverse;
  .ql-container {
    border-radius: 10px 10px 0 0 !important;
    border: 1px solid #ccc !important;
    border-bottom: 0px !important;
    height: 280px;
  }
  .ql-toolbar {
    border-radius: 0 0 10px 10px !important;
    text-align: left !important;
    background-color: #f7f9fb !important;
  }
  overflow-y: auto;
}
.color_position {
  .ql-container {
    .ql-editor {
      font-size: 14px;
    }
  }
  .ql-snow .ql-picker.ql-expanded .ql-picker-options {
    top: auto;
    bottom: 110%;
  }
  .ql-snow > .ql-formats {
    .ql-link {
      display: none;
    }
  }
}
.textOverFlow p {
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
}
.textOverFlow2 p {
  word-break: break-all;
  text-overflow: ellipsis;
}
.addContract {
  max-width: 800px;
  width: 100%;
  height: 48px;
  font-size: 20px;
  font-weight: 700;
  border: none;
}
.addContract:focus {
  outline: none;
}
.addEvent {
  max-width: 790px;
  width: 100%;
  height: 48px;
  font-size: 20px;
  font-weight: 700;
  border: none;
}
.addEvent:focus {
  outline: none;
}
.only-text-btn {
  &.Mui-disabled {
    color: #ced4d7 !important;
    background: transparent !important;
  }
}
.buttomcursor {
  pointer-events: none;
  cursor: default;
  color: #ced4d7;
}
.textFiled-Writer {
  height: 40px !important;
}
.contract-table {
  width: 99%;
  border-collapse: collapse;
  border-spacing: 0px;
  text-align: center;
  td {
    letter-spacing: -1px !important;
    background-color: white;
    border: 0.1px solid #ced4d7;
  }
}
.table-head-sortLabel {
  font-size: 16px;
  color: #535353 !important;
  .MuiTableSortLabel-icon {
    display: none !important;
  }
}
.td-fontSetting {
  font-size: 14px !important;
  color: #707070 !important;
}
.none-panel {
  background: linear-gradient(180deg, rgba(238, 174, 202, 1) 0%, rgba(148, 187, 233, 1) 100%);
  transition: all 0.3s;
  visibility: hidden;
  height: 0vh;
}
.panel-1 {
  visibility: visible !important;
  height: auto !important;
}
.panel-2 {
  visibility: visible !important;
  height: auto !important;
  padding: 20px 0px;
}
.contract-date2 {
  width: 100%;
  .MuiOutlinedInput-root {
    input {
      text-align: right;
    }
    border: none !important;
  }
  .MuiInputLabel-root {
    left: 250px !important;
  }
  .MuiInputBase-root {
    border-radius: 10px !important;
    .MuiInputAdornment-root {
      margin-left: -315px;
      margin-top: -20px;
    }
  }
}
.event_datepicker {
  width: 100%;
  .MuiOutlinedInput-root {
    input {
      text-align: right;
    }
    border: none !important;
  }
  .MuiInputLabel-root {
    left: 120px !important;
    top: -7px !important;
  }
  .MuiInputBase-root {
    border-radius: 10px !important;
    .MuiInputAdornment-root {
      margin-left: -200px;
      margin-top: -20px;
    }
    fieldset {
      border-color: #c4c4c4 !important;
    }
  }
}
.event_datepicker_contract {
  width: 100%;
  .MuiOutlinedInput-root {
    input {
      text-align: right;
    }
    border: none !important;
  }
  .MuiInputLabel-root {
    left: 120px !important;
    top: -7px !important;
  }
  .MuiInputBase-root {
    border-radius: 10px !important;
    .MuiInputAdornment-root {
      margin-left: -130px;
      margin-top: -20px;
    }
    fieldset {
      border-color: #c4c4c4 !important;
    }
  }
}
.filter_datepicker {
  width: 100%;
  .MuiOutlinedInput-root {
    input {
      text-align: right;
    }
    border: none !important;
  }
  .MuiInputLabel-root {
    left: 120px !important;
    top: -7px !important;
  }
  .MuiInputBase-root {
    border-radius: 10px !important;
    .MuiIconButton-root {
      margin-left: 35px;
    }
    .MuiInputAdornment-root {
      margin-left: -200px;
      margin-top: -20px;
    }
    fieldset {
      border-color: #c4c4c4 !important;
    }
  }
}
.event_email_datepicker {
  width: 100%;
  div > div > button {
    margin-right: -300px;
    width: 340px;
    justify-content: start;
    padding: 13px 8px !important;
    border-radius: 0 !important;
  }
  .MuiInputLabel-shrink {
    display: none !important;
  }
  label {
    color: #2f2f2f !important;
  }
  .MuiOutlinedInput-root {
    input {
      text-align: right;
    }
    border: none !important;
  }
  .MuiInputLabel-root {
    left: 120px !important;
    top: -7px !important;
  }
  .MuiInputBase-root {
    border-radius: 10px !important;
    .MuiInputAdornment-root {
      margin-left: -290px;
      margin-top: -20px;
    }
    fieldset {
      border-color: #c4c4c4 !important;
      legend {
        width: 0px !important;
      }
    }
  }
}
.event_email_datepicker_disabled > div {
  input {
    color: rgba(0, 0, 0, 0.26) !important;
  }
  div {
    button {
      background-color: transparent !important;
      color: rgba(0, 0, 0, 0.26) !important;
    }
  }
}
.contract-date {
  width: 100%;
  .MuiOutlinedInput-root {
    input {
      text-align: right;
    }
    border: none !important;
  }
  .MuiInputLabel-root {
    left: 250px !important;
  }
  .MuiInputBase-root {
    border-radius: 10px !important;
    .MuiInputAdornment-root {
      margin-left: -120px;
      margin-top: -20px;
    }
  }
}
.profile-input {
  .MuiFormControl-root {
    .MuiInputBase-root {
      height: 57px !important;
    }
  }
}
.MuiTypography-root {
  letter-spacing: -0.6px !important;
}

.MuiAvatar-fallback {
  color: "#000000" !important;
}
.td-Setting {
  font-size: 14px;
  max-width: 150px;
  padding: 14px !important;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
}
.td-Setting2 {
  font-size: 14px;
  max-width: 150px;
  padding: 14px !important;
}
.td-send-Setting {
  font-size: 14px;
  max-width: 150px;
  padding: 0px !important;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  height: 40px;
  max-height: 40px;
}
.td-Setting2 {
  font-size: 14px;
  max-width: 150px;
  padding: 12px !important;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
}
.tab-disabled {
  background-color: white !important;
  color: #cdd2d3 !important;
  cursor: pointer !important;
  pointer-events: none !important;
}
.MuiAvatar-circular {
  color: #ced4da !important;
}

.bg-transparent {
  background-color: transparent !important;
}

.change-select-radius {
  min-height: 60px !important;
  border: 1px !important;
  border-radius: 0 10px 10px 0 !important;
  color: #535353 !important;
}
.change-select-radius-filter-open {
  fieldset {
    border-bottom: none !important;
  }
  border-radius: 10px 0 0 0 !important;
}
input {
  color: #000000 !important;
}
.snackBar-center {
  bottom: 80px !important;
}
.snackBar-center .MuiPaper-root {
  justify-content: center !important;
  border-radius: 30px !important;
  height: 35px !important;
  .MuiSnackbarContent-message {
    padding: 0 !important;
  }
}
.baege-active-change {
  background-color: #f55cc7 !important;
  min-width: 8px !important;
  height: 8px !important;
  border-radius: 50%;
  position: absolute;
  top: 20px;
  left: 47%;
}
.baege-active-delete {
  background-color: #f55cc7 !important;
  min-width: 8px !important;
  height: 8px !important;
  border-radius: 50%;
  position: absolute;
  top: 20px;
  left: 65%;
}
.tooltip-light {
  .MuiTooltip-tooltip {
    box-shadow: 1px 1px 4px lightgrey;
    background-color: white !important;
    padding: 2px 12px;
  }
}
.filter-data-box {
  border: 1px dashed #bfc4c7;
  border-radius: 10px;
  background-color: #f7f9fb !important;
}
.nextStepBtn {
  background-color: #cdd2d3 !important;
}

.MuiSwitch-track-none {
  background-color: #dbdbdb !important;
}
.ql-image {
  svg {
    display: none !important;
  }
  background: url("./images/paperclip-regular-24.png") no-repeat !important;
  background-size: 16px !important;
  background-position: center !important;
  transform: rotate(270deg);
}
.history-content-strong {
  cursor: pointer;
  text-decoration: underline;
}
.first-contract {
  margin: 0 auto;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
}
.history-container {
  padding-right: 16px;
  max-height: 952px;
  overflow: scroll;
}
.significants-container {
  padding-right: 16px;
  max-height: 614px;
  overflow: scroll;
}
.significantInput input:focus {
  height: 3rem;
}
.verticalLine {
  height: 40px;
  border-left: 3px solid #f1f1f5;
  margin-right: 16px;
  margin-top: 8px;
}
.disabledcolor {
  background-color: rgba(255, 255, 255, 1);
}
.setting_disabled {
  .Mui-disabled {
    background-color: #f7f9fb !important;
    opacity: 1;
  }
  .MuiInputBase-root {
    background-color: #f7f9fb;
  }
}
.MuiDialog-container .css-lqrb7j-MuiTypography-root {
  white-space: pre-line;
}
.notice_chip {
  background-color: #fbe8f1 !important;
  color: #e67daf !important;
}
.update_chip {
  background-color: #ebf2ff !important;
  color: #0c65ff !important;
}
.etc_chip {
  background-color: #e1eff6 !important;
  color: #668b9e !important;
}
.image-icon {
  opacity: 0.5;
  &.active {
    opacity: 1;
    background-color: white;
    border: 1px solid #cdd2d3;
    border-radius: 10px;
  }
  &.active:hover {
    background-color: white;
  }
}
.outsourcing-history-img {
  background-color: #ced4d7;
  width: 100%;
  height: 268px;
  background-size: cover;
  filter: brightness(0.9);
  &.icon {
    opacity: 0.9;
    padding: 50px;
  }
}
.outsourcing-history-menu {
  position: absolute !important;
  right: 0px;
  z-index: 1;
  margin-right: 4px !important;
}
.outsourcing-history-backCard {
  background-color: white !important;
  border-radius: 10px !important;
  padding: 40px;
}
.mypage_blue_underline {
  text-decoration: underline !important;
  cursor: pointer !important;
  color: #0c65ff !important;
}
.mypage_red_underline {
  text-decoration: underline !important;
  cursor: pointer !important;
  color: #ff7b89 !important;
}
.mypage_gray_underline {
  text-decoration: underline !important;
  cursor: pointer !important;
  color: #535353 !important;
}
.referrerIcon:hover {
  background-color: #0c65ff !important;
}
.referrerIcon:active {
  background-color: #0c65ff !important;
}
.referrerIcon:focus {
  background-color: #0c65ff !important;
}

.custom_pagination_item_active {
  color: #fff !important;
  background-color: #5c38ff !important;
  font-size: 1rem !important;
  font-weight: 400 !important;
  line-height: 1.6 !important;
  letter-spacing: 0.01071em !important;
  border-radius: 16px !important;
  text-align: center !important;
  box-sizing: border-box !important;
  min-width: 32px !important;
  height: 32px !important;
  padding: 0 6px !important;
  margin: 0 3px !important;
  min-height: 0px !important;
}
.custom_pagination_item {
  color: rgba(0, 0, 0, 0.87) !important;
  font-size: 1rem !important;
  font-weight: 400 !important;
  line-height: 1.6 !important;
  letter-spacing: 0.01071em !important;
  border-radius: 16px !important;
  text-align: center !important;
  box-sizing: border-box !important;
  min-width: 32px !important;
  height: 32px !important;
  padding: 0 6px !important;
  margin: 0 3px !important;
  min-height: 0px !important;
  box-shadow: none !important;
}
.custom_pagination_arrow_hide {
  color: transparent !important;
  cursor: cell !important;
}

.compactPicker {
  width: 22px;
  height: 22px;
  position: absolute;
  padding: 0;
  margin: 0;
  left: 10px;
  top: 50%;
  margin-top: -17px;
  button {
    width: 100%;
    height: 100%;
  }
}
.compactColorPicker {
  position: absolute !important;
  left: 20px;
  top: 17px;
}

.compactPickerBox {
  position: relative;
}

.compactIndent {
  input {
    text-indent: 30px;
  }
}
.event_works_lists {
  color: #0c65ff !important;
  text-decoration: underline !important;
  font-size: 14px !important;
}
.event_delete_cancel {
  color: #ff4d60 !important;
  text-decoration: underline !important;
  font-size: 14px !important;
}
.alert_line {
  white-space: pre-line;
}
.writer_event_chart {
  .apexcharts-canvas .apexcharts-svg {
    border-radius: 20px;
  }
  .apexcharts-legend-series {
    text-align: left;
    align-items: center;
    display: flex;
    .apexcharts-legend-marker {
      margin-right: 8px;
    }
  }
}
.donut_legend .legend_item {
  align-items: center;
}
.donut_legend .legend_item .legend_color {
  width: 15px;
  height: 15px;
  margin-right: 8px;
}

.donut_legend .legend_item .legend_name {
  font-size: 14px;
  margin-right: 4px;
}

.donut_legend .legend_item .legend_name_empty {
  font-size: 14px;
  margin-right: 4px;
  color: #4f5252;
}

.donut_legend .legend_item .legend_count {
  font-size: 14px;
  font-weight: bold;
}
.error_detail_none > div > p {
  display: none !important;
}
.menu_disabled {
  background-color: #d7d7d7 !important;
  color: #656668 !important;
}
.apexcharts-bar-area:hover {
  stroke: transparent !important;
}
.event_work_search .suiInput_icon {
  background: white;
  position: absolute;
  right: 2px;
  height: 30px;
  padding: 0;
  display: flex;
  align-items: center;
  justify-content: center;
}
.event_work_search .small_search {
  padding-top: 6px;
  padding-bottom: 6px;
  padding-right: 40px;
  min-height: 34px;
}
.event_work_search .Mui-focused {
  padding-right: 40px !important;
}
.apexcharts-tooltip {
  padding: 10px;
  text-align: start;
}
.publisher_chip {
  width: auto;
  min-width: 115px;
  height: 35px;
  border: 1px solid #5c38ff !important;
  color: #5c38ff !important;
  padding: 0px 8px;
  margin: 0px 5px 3px 0px;
  border-radius: 10px;
  font-size: 16px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.publisher_chip_no {
  width: auto;
  min-width: 115px;
  height: 35px;
  background-color: #f1f1f5 !important;
  color: #9aa0a6 !important;
  padding: 0px 8px;
  margin: 0px 5px 3px 0px;
  border-radius: 10px;
  font-size: 16px;
  display: flex;
  align-items: center;
  justify-content: center;
}
